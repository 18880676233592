<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-toolbar card>
        <v-select
          v-model="year"
          :items="years"
          label="Year"
          prepend-inner-icon="event"
        />
        <v-select
          v-model="filter"
          :items="['OneXOX','XOX']"
          label="Filter by"
          clearable
        />
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-timeline
          align-top
          dense
        >
          <v-timeline-item
            v-for="(item, month) in items"
            :key="month"
            color="amber"
            small
          >
            <v-layout pt-3>
              <v-flex xs3>
                <strong>{{ month }}</strong>
              </v-flex>
              <v-flex>
                <v-data-table
                  :headers="headers"
                  :items="filteredItems(month)"
                  :loading="loading"
                  :total-items="1"
                  class="px-1 pb-1"
                  item-key="month"
                  hide-actions
                >
                  <template
                    slot="items"
                    slot-scope="props"
                  >
                    <tr>
                      <td class="text-xs-left">
                        {{ props.item.kpi }}
                      </td>
                      <td class="text-xs-center">
                        {{ props.item.dealerCount }}
                      </td>
                      <td class="text-xs-center">
                        {{ props.item.activations }}
                      </td>
                    </tr>
                  </template>
                  <template #footer>
                    <tr>
                      <td class="text-xs-center info">
                        &nbsp;
                      </td>
                      <td class="text-xs-center info">
                        {{ totals(month).dealerCount }}
                      </td>
                      <td class="text-xs-center info">
                        {{ totals(month).activations }}
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>
  </panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'

const reportTitle = 'Dealer KPI'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
    // Chart: () => import(/* webpackChunkName: "chart" */ '@/components/Chart'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Monthly Excerpt', disabled: false, to: '/management/monthly_view',
        },
        {
          text: 'Dealer KPI', disabled: true,
        },
      ],
      expand: true,
      filter: 'All',
      headers: [
        {
          align: 'Left',
          sortable: false,
          text: ' ',
          value: 'KPI',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Dealer Count',
          value: 'dealerCount',
        },
        {
          align: 'center',
          sortable: false,
          text: 'Activations @RM30',
          value: 'activations',
        },
      ],
      items: [],
      loading: false,
      menu: false,
      year: null,
      years: [],
      noDataText: "There's nothing to display.",
      noResultText: 'No data that fits that criteria.',
      title: reportTitle,
    }
  },
  watch: {
    year: function (val) {
      this.getDealerKpi(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    const maxDate = now.toFormat('yyyy')
    for (let year = maxDate; year > 2021; year--) {
      this.years.push(year)
    }
    this.year = maxDate
  },
  methods: {
    totals: function (month) {
      const items = this.filteredItems(month)
      const totals = {
        dealerCount: 0,
        activations: 0,
      }

      for (const item of items) {
        totals.dealerCount += item.dealerCount * 1
        totals.activations += item.activations * 1
      }

      return totals
    },
    onexox: function (month) {
      return this.items[month][0]
    },
    xox: function (month) {
      return this.items[month][1]
    },
    all: function (month) {
      const all = []

      for (let i = 0; i < this.onexox(month).length; i++) {
        all.push({
          kpi: this.onexox(month)[i].kpi,
          dealerCount: this.onexox(month)[i].dealerCount + (this.xox(month) ? this.xox(month)[i].dealerCount : 0),
          activations: this.onexox(month)[i].activations + (this.xox(month) ? this.xox(month)[i].activations : 0),
        })
      }

      return all
    },
    filteredItems: function (month) {
      if (this.filter === 'OneXOX') return this.onexox(month)
      else if (this.filter === 'XOX') return this.xox(month)
      else return this.all(month)
    },
    getDealerKpi (year) {
      this.loading = true
      const params = createGetParams({
        year: year,
      })
      this.loading = true
      this.$rest.get('getDealerKpiByYear.php', params)
        .then(function (response) {
          this.items = response.data
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
